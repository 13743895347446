import { render, staticRenderFns } from "./jd-list.vue?vue&type=template&id=477126e6&scoped=true"
import script from "./jd-list.vue?vue&type=script&lang=js"
export * from "./jd-list.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "477126e6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {JdItem: require('/ntweb/components/jd-item.vue').default})
