
import Jd from "~/models/jd";
import {USAGE_ADMIN, USAGE_FAV, USAGE_OWNER, USAGE_REFEREE, USAGE_USER} from "~/constants/jd";
import {
    JD_BECOME_REFEREE, JD_CANCEL_REFEREE,
    JD_DEL,
    JD_EDIT,
    JD_OFFLINE,
    JD_OFFLINE_BY_ADMIN,
    JD_ONLINE, JD_ONLINE_BY_ADMIN, JD_REJECT,
    JD_RESUME,
    JD_UN_FAV
} from "~/constants/bus";
import {
    STATUS_APPLYING,
    STATUS_APPROVED,
    STATUS_OFFLINE_BY_ADMIN,
    STATUS_OFFLINE_BY_OWNER,
    STATUS_REJECTED
} from "~/constants/audit";

export default {
    name: "JdItem",
    props: {
        usage: {
            type: String,
            default: USAGE_USER, // owner, admin
        },
        jd: {
            type: Object,
            default: function () {
                return new Jd()
            }
        }
    },
    computed: {
        isUser() {
            return this.usage === USAGE_USER
        },
        isUserFav() {
            return this.usage === USAGE_FAV
        },
        isOwner() {
            return this.usage === USAGE_OWNER
        },
        isAdmin() {
            return this.usage === USAGE_ADMIN
        },
        isReferee() {
            return this.usage === USAGE_REFEREE
        },
        isApplying() {
            return this.jd.statusId === STATUS_APPLYING
        },
        isOnline() {
            return this.jd.statusId === STATUS_APPROVED
        },
        isReject() {
            return this.jd.statusId === STATUS_REJECTED
        },
        isOfflineByOwner() {
            return this.jd.statusId === STATUS_OFFLINE_BY_OWNER
        },
        isOfflineByAdmin() {
            return this.jd.statusId === STATUS_OFFLINE_BY_ADMIN
        },
    },
    methods: {
        onItemClick() {
            if (this.isUser) {
                this.onOpen()
            }
        },
        onOpen() {
            sessionStorage.setItem('store', JSON.stringify(this.$store.state))
            let url = this.$router.resolve({
                path: "/jds/"+this.jd._id,
            })
            window.open(url.href, '_blank')
        },
        onUnFav() {
            console.log("===onUnFav=$bus.$emit==")
            this.$bus.$emit(JD_UN_FAV,this.jd)
        },
        onEdit() {
            this.$bus.$emit(JD_EDIT,this.jd)
        },
        onResume() {
            this.$bus.$emit(JD_RESUME,this.jd)
        },
        onDelete() {
            this.$bus.$emit(JD_DEL,this.jd)
        },
        onBecomeReferee() {
            this.$bus.$emit(JD_BECOME_REFEREE,this.jd)
        },
        onCancelReferee() {
            this.$bus.$emit(JD_CANCEL_REFEREE,this.jd)
        },
        onOffline() {
            this.$bus.$emit(JD_OFFLINE,this.jd)
        },
        onOnline() {
            this.$bus.$emit(JD_ONLINE,this.jd)
        },
        onReject() {
            this.$bus.$emit(JD_REJECT,this.jd)
        },
        onOfflineByAdmin() {
            this.$bus.$emit(JD_OFFLINE_BY_ADMIN,this.jd)
        },
        onOnlineByAdmin() {
            this.$bus.$emit(JD_ONLINE_BY_ADMIN,this.jd)
        },
    }
}
