

import {USAGE_USER} from "~/constants/jd";

export default {
    name: 'JdList',
    props: {
        hasPagination: {
            type: Boolean,
            default: true,
        },
        usage: {
            type: String,
            default: USAGE_USER,
        },
        currentPage: {
            type: Number,
            default: 1,
        },
        total: {
            type: Number,
            default: 0,
        },
        jds: Array,
    },
    data() {
        return {
            newCurrentPage: this.currentPage
        };
    },
    methods: {
        handleCurrentChange(newPage) {
            this.$emit("update:currentPage",newPage)
        },
    },
}
