export default class JdLink {

    constructor(options) {
        this.name = options?.name || ""
        this.url = options?.url || ""
    }

    clone() {
        return new JdLink({
            name: this.name,
            url: this.url,
        })
    }
}
