export const USAGE_USER = "user"
export const USAGE_FAV = "fav"
export const USAGE_OWNER = "owner"
export const USAGE_ADMIN = "admin"
export const USAGE_REFEREE = "referee"

export const UPDATE_TIME_NOW = 1
export const UPDATE_TIME_IN_PAST_3_DAYS = 3
export const UPDATE_TIME_IN_PAST_5_DAYS = 5
export const UPDATE_TIME_IN_PAST_10_DAYS = 10


