import {
    STATUS_APPLYING, STATUS_APPROVED,
    STATUS_NEW, STATUS_OFFLINE_BY_ADMIN,
    STATUS_OFFLINE_BY_OWNER, STATUS_REJECTED
} from "@/constants/audit";
import {
    getDegreeName,
    getExperienceName, getJobValue,
    getSalaryIdBySalaryTo,
    getSalaryName,
    salaryMultipleName,
} from "~/utils/job";
import {
    cities,
    cityName2,
    counties,
    countyName2,
    firstCityId,
    firstCountyId,
    provinceName
} from "~/utils/area";
import Company from "~/models/company";
import JdLink from "@/models/jd-link";
import {fen2KYuan, fen2Yuan, yuan2Fen} from "@/utils/money";
import moment from "moment";

const DESC = "职位描述： \n1. 职位描述1 \n2. 职位描述2 \n... \n\n"
    + "任职要求： \n1. 要求描述1 \n2. 要求描述2 \n... \n\n"
    + "其他可选说明： \n1. 其他说明1 \n2. 其他说明2 \n... \n\n"

export default class Jd {

    constructor(options) {
        this._id = options?._id || 0

        this.createdAt = options?.createdAt || ""
        this.updatedAt = options?.updatedAt || ""
        this.userId = options?.userId || 0
        this.companyId = options?.companyId || 0
        this.jobCatId = options?.jobCatId || 0
        this.jobSubCatId = options?.jobSubCatId || 0
        this.jobId = options?.jobId || 0
        this.job = options?.job || getJobValue(this.jobCatId,this.jobSubCatId,this.jobId)
        this.experienceId = options?.experienceId || 0
        this.degreeId = options?.degreeId || 0
        this.salaryId = options?.salaryId || 0
        this.salaryFrom = options?.salaryFrom || 0
        this.salaryTo = options?.salaryTo || 0
        this.salaryMultiple = options?.salaryMultiple || 0.0
        this.title = options?.title || ""
        this.keyWords = options?.keyWords || ""
        this.welfares = options?.welfares || ""
        this.desc = options?.desc || DESC
        this.provinceId = options?.provinceId || 0
        this.cityId = options?.cityId || 0
        this.countyId = options?.countyId || 0
        this.address = options?.address || ""
        this.cvReviewPrice = options?.cvReviewPrice || 0
        this.itvPrice = options?.itvPrice || 0
        this.itvCvReviewPrice = options?.itvCvReviewPrice || 0
        this.careerPlanningPrice = options?.careerPlanningPrice || 0

        this.statusId = options?.statusId || 0
        this.statusText = options?.statusText || "-"
        this.auditExtra = options?.auditExtra || ""

        this.jdLinks = []
        let links = options?.jdLinks
        if (links && links.length > 0) {
            for (let i = 0; i < links.length; i++) {
                let link = links[i]
                this.jdLinks.push(new JdLink(link))
            }
        }

        this.company = new Company(options?.company)
    }

    get shortUpdatedAt() {
        return moment(this.updatedAt).format("YYYY-MM-DD")
    }

    get statusShort() {
        switch (this.statusId) {
            case STATUS_NEW:
                return "新建";
            case STATUS_APPLYING:
                return "审核中";
            case STATUS_APPROVED:
                return "已上线";
            case STATUS_REJECTED:
                return "待修改";
            case STATUS_OFFLINE_BY_OWNER:
                return "已下线";
            case STATUS_OFFLINE_BY_ADMIN:
                return "被管理员下线";
        }
        return "状态未知"
    }

    get isApproved() {
        return this.statusId == STATUS_APPROVED
    }

    get isNew() {
        return this._id <= 0
    }

    get nickName() {
        return this.company.nickName
    }

    get logoUrl() {
        return this.company.logoUrl
    }

    get industryName() {
        return this.company.industryName
    }

    get industryName2() {
        return this.company.industryName2
    }

    get industryName3() {
        return this.company.industryName3
    }

    get industryNames() {
        return this.company.industryNames
    }

    get shortIndustryNames() {
        return this.company.shortIndustryNames
    }

    get scaleName() {
        return this.company.scaleName
    }

    get stageName() {
        return this.company.stageName
    }

    get experienceName() {
        return "经验"+getExperienceName(this.experienceId)
    }

    get degreeName() {
        return getDegreeName(this.degreeId)
    }

    get salaryName() {
        let salaryId = options?.salaryId || 0
        let salaryTo = options?.salaryTo || 0
        //没有salaryId，通过salaryTo计算
        if (salaryId <= 0 && salaryTo > 0) {
            salaryId = getSalaryIdBySalaryTo(salaryTo)
        }
        return getSalaryName(salaryId)
    }

    get salaryFromName() {
        return fen2KYuan(this.salaryFrom)
    }

    get salaryToName() {
        return fen2KYuan(this.salaryTo)
    }

    get salaryMultipleName() {
        return salaryMultipleName(this.salaryMultiple)
    }

    get salaryRange() {
        return this.salaryFromName +"-"+ this.salaryToName +" * "+ this.salaryMultipleName
    }

    // fixed price, ¥25 yuan
    get cvReviewPriceName() {
        return fen2Yuan(this.cvReviewPrice)
    }

    get itvPriceName() {
        return fen2Yuan(this.itvPrice)
    }

    get itvCvViewPriceName() {
        return fen2Yuan(this.itvCvReviewPrice)
    }

    get careerPlanningPriceName() {
        return fen2Yuan(this.careerPlanningPrice)
    }

    get provinceName() {
        return provinceName(this.provinceId)
    }

    get cityName() {
        return cityName2(this.provinceId, this.cityId)
    }

    get countyName() {
        return countyName2(this.provinceId, this.cityId, this.countyId)
    }

//===
    get formJobValues() {
        return [this.jobCatId, this.jobSubCatId, this.jobId]
    }

    set formJobValues(values) {
        this.jobCatId = values && values.length>0 ? values[0] : 0
        this.jobSubCatId = values && values.length>1 ? values[1] : 0
        this.jobId = values && values.length>2 ? values[2] : 0
    }

    get formSalaryFromYuan() {
        //return this.salaryFrom ? this.salaryFrom / 100 : null
        return fen2Yuan(this.salaryFrom)
    }

    set formSalaryFromYuan(newValue) {
        let val = newValue || 0
        // this.salaryFrom = val * 100
        this.salaryFrom = yuan2Fen(val)
    }

    get formSalaryToYuan() {
        //return this.salaryTo ? this.salaryTo / 100 : null
        return fen2Yuan(this.salaryTo)
    }

    set formSalaryToYuan(newValue) {
        let val = newValue || 0
        //this.salaryTo = val * 100
        this.salaryTo = yuan2Fen(val)
    }

    get formSalaryMultiple() {
        return this.salaryMultiple || null
    }

    set formSalaryMultiple(newValue) {
        this.salaryMultiple = newValue || 0
    }

    get formExperienceId() {
        return this.experienceId || null
    }
    set formExperienceId(newValue) {
        this.experienceId = newValue
    }

    get formDegreeId() {
        return this.degreeId || null
    }

    set formDegreeId(newValue) {
        this.degreeId = newValue
    }

    get formSalaryId () {
        return this.salaryId ? this.salaryId : getSalaryIdBySalaryTo(this.salaryTo)
    }

    set formSalaryId (newValue) {
        this.salaryId = newValue
    }

    get formProvinceId () {
        return this.provinceId || null
    }

    set formProvinceId (newValue) {
        this.provinceId = newValue
        this.cityId = this.firstCityId
        this.countyId = this.firstCountyId
    }

    get firstCityId() {
        const cs = cities(this.provinceId)
        return firstCityId(cs)
    }

    get firstCountyId() {
        const cs = counties(this.provinceId, this.cityId)
        return firstCountyId(cs)
    }

    get formCityId() {
        return this.cityId || null
    }

    set formCityId(newValue) {
        this.cityId = newValue
        this.countyId = this.firstCountyId
    }

    get formCountyId() {
        return this.countyId || null
    }

    set formCountyId (newValue) {
        this.countyId = newValue
    }

//===

    toData() {
        let vo = this.commonData()
        vo._id = this._id
        vo.statusId = this.statusId
        vo.statusText = this.statusText
        vo.auditExtra = this.auditExtra
        return vo
    }

    toCreateData() {
        let vo = this.commonData()
        return vo
    }

    toUpdateData() {
        let vo = this.commonData()
        vo._id = this._id
        return vo
    }

    commonData() {
        return {
            companyId: this.companyId,
            jobCatId: this.jobCatId,
            jobSubCatId: this.jobSubCatId,
            jobId: this.jobId,
            job: this.job || getJobValue(this.jobCatId,this.jobSubCatId,this.jobId),
            experienceId: this.experienceId,
            degreeId: this.degreeId,
            salaryId: this.salaryId,
            salaryFrom: this.salaryFrom,
            salaryTo: this.salaryTo,
            salaryMultiple: this.salaryMultiple,
            title: this.title,
            keyWords: this.keyWords,
            welfares: this.welfares,
            desc: this.desc,
            provinceId: this.provinceId,
            cityId: this.cityId,
            countyId: this.countyId,
            address: this.address,
            cvReviewPrice: this.cvReviewPrice,
            itvPrice: this.itvPrice,
            itvCvReviewPrice: this.itvCvReviewPrice,
            careerPlanningPrice: this.careerPlanningPrice,
            company: this.company?.toData(),
            jdLinks: this.jdLinks,
        }
    }

    clone() {
        let options = this.toData()
        return new Jd(options)
    }

    toJSON() {
        return {...this}
    }
}
